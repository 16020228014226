import { useState, useEffect } from 'react';
import request from '../utils/request';
import { AxiosRequestConfig } from 'axios';

interface State {
    data: any | null;
    error?: Error | null;
    loading: boolean;
    loaded: boolean;
}

interface StateWithCallback extends State {
    update: (requestConfig?: AxiosRequestConfig) => Promise<void>;
}

function useRequest(requestConfig?: AxiosRequestConfig, defaultData?: any): StateWithCallback {
    // const { isAuth, data: authData, login, logout } = useContext(AuthContext);
    const [state, setState] = useState({
        data: defaultData,
        error: null,
        loading: false,
        loaded: false,
    });

    function update(): Promise<void> {
        return request(requestConfig)
            .then(({ data }) => {
                setState({ data, error: null, loading: false, loaded: true });
            })
            .catch((error: any) => {
                setState({ data: null, error, loading: false, loaded: true });
            });
    }

    useEffect(() => {
        setState({ data: null, error: null, loading: true, loaded: state.loaded });

        update();
    }, [JSON.stringify(requestConfig)]);

    return { ...state, update };
}

export default useRequest;
