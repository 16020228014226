import { combineReducers } from "@reduxjs/toolkit";
import authenticateSlice, { initialState as authenticateInitialState } from "./authenticate";
import authoriseSlice, { initialState as authoriseInitialState } from "./authorise";
import customerSlice from './customer';
import { ApplicationInitialState } from "@/store";
import bmApiService from '@/services/bmApi';
import configurationSlice from "./configuration";


export const applicationInitialState: ApplicationInitialState = {
    authenticate: authenticateInitialState,
    authorise: authoriseInitialState
}

const rootReducer = combineReducers({
    authenticate: authenticateSlice.reducer,
    authorise: authoriseSlice.reducer,
    customer: customerSlice.reducer,
    configuration: configurationSlice.reducer,
    [bmApiService.reducerPath]: bmApiService.reducer
});

export default rootReducer;