import qs from 'qs';
import { CountryCode, LanguageCode } from "@/types";

export function readConfigurationProperty(property: string, defaultValue?: any): any {
    if (!(window as any).BOKAMERA || !(window as any).BOKAMERA.configuration) {
        // console.log('No external configuration found for BOKAMERA');
        return defaultValue;
    }

    const setting = (window as any).BOKAMERA.configuration[property];

    if (setting === 'true') return true;

    if (setting === 'false') return false;

    if (setting === undefined) {
        return defaultValue;
    }

    return setting;
}

export function resizeParentIframe(size: number | void) {
    if (!("parentIFrame" in window)) {
      return;
    }
  
    if (typeof size === "number") {
      // @ts-ignore
      parentIFrame.autoResize(false);
      // @ts-ignore
      parentIFrame.size(size);
    } else {
      // @ts-ignore
      parentIFrame.autoResize(true);
    }
  }

  export function getErrorMessage(error: any) {
    return error?.response?.data?.ResponseStatus?.Message || error?.message;
  }


export const realmParamsMapping =  {
  bookmore: '1',
  ['bookmore-admin']: '2'
};

export const getRedirectUri = (backUriParam: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined) => {
  let redirectUri;

  // this is the redirect for the hosted embed-booking (in Iframe)
  if (Array.isArray(backUriParam)) {
    redirectUri = `${backUriParam[1]}?success=true`;
  }

  // this is the redirect for the NOT hosted embed-booking
  else if (typeof backUriParam === 'string') {
    redirectUri = `${backUriParam}?success=true`
  }

  return redirectUri;
};


export const getCountryCode = (language: LanguageCode): CountryCode => {
  if (language === "sv" || !language) {
    return "se";
  }

  return language;
};

/**
 * 
 * @param errorResponse usually in error.response.data 
 * @returns 
 */
export const getErrorMessageFromApiError = (error: any) => {
  let errorMessage;

  if (error.response?.data.ResponseStatus?.Message) {
    errorMessage = error.response.data.ResponseStatus.Message;
  } else if (error?.message) {
    errorMessage = error.message;
  } else {
    errorMessage = "Uknown error occured";
  }

  return errorMessage;
};