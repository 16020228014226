import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import bmApiService from '@/services/bmApi';
import { authListener } from './reducers/authenticate';
import { setupListeners } from '@reduxjs/toolkit/query'



export type LoadEntity = {
    isLoaded: boolean
    isError: boolean
    isLoading: boolean
}

export type authenticateInitialState = LoadEntity & {
    isLoggedIn: boolean
    error: any
}

type User = {
    CompanyId?: string;
    Id?: string;
    Firstname?: string;
    Lastname?: string;
    Email?: string;
    WorkerId?: string;
    Phone?: string;
}

export type authoriseInitialState = LoadEntity & {
    error: any
}

export type ApplicationInitialState = {
    authenticate: authenticateInitialState
    authorise: authoriseInitialState
}


const applicationInitialState: ApplicationInitialState = {
    authenticate: {
        isError: false,
        isLoading: false,
        isLoaded: false,
        isLoggedIn: false,
        error: undefined
    },
    authorise: {
        isError: false,
        isLoaded: false,
        isLoading: false,
        error: undefined
    },
}

export const createStoreWithInitialState = (initialState: ApplicationInitialState = applicationInitialState) => {
    
    const store =  configureStore({
        preloadedState: initialState,
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => [
            ...getDefaultMiddleware().concat(
                bmApiService.middleware,
                authListener.middleware,
            ),
        ],
    });
    
    setupListeners(store.dispatch)

    return store;
}

export type ApplicationDispatch =  ReturnType<typeof createStoreWithInitialState>['dispatch'];

export type ApplicationState =  ReturnType<ReturnType<typeof createStoreWithInitialState>['getState']>

// ReturnType<typeof store.getState>;

